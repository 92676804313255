import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DOCUMENT, DatePipe } from '@angular/common';
import { AbstractControl } from '@angular/forms';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = !isNaN(Date.parse(control.value));
    return isValid ? null : { 'invalidDate': { value: control.value } };
  };
}
@Component({
  selector: 'app-error-crr-additional-hltcre',
  templateUrl: './error-crr-additional-hltcre.component.html',
  styleUrls: ['./error-crr-additional-hltcre.component.css']
})
export class ErrorCrrAdditionalHltcreComponent implements OnInit,AfterViewInit {
  @Output() public collapseAdditionalHltcreFields = new EventEmitter<any>();
  @Input() inputLabel;
  @Input() inputValue;
  @Input() additionalHltCareDesMapping;
  @Input() changeBtnCls: string;
  @Input() encodeDateFormat;
  @Input() getAddHealthDate;
  public amountQualifier = "BE";
  public CodeThroughQualifier = "BI";
  public codeFromQulList: any = ['BP', 'BG', 'DR', 'BE'];
  public additionalHltCareForm;
  viewLoaded :boolean;
  public readonly maxLengthInfo = {
    industryCode: '30',
    dollar: '8',
    cent: '2',
  }
  private dateFormat = 'yyyy-MM-dd';
  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    this.initializeForm();
  }

  ngAfterViewInit(){
    this.viewLoaded = true;
  }

  cancelClick() {
    const event = {
      type: 'cancel',
      field: this.inputLabel
    }
    this.collapseAdditionalHltcreFields.emit(event);
  }

  initializeForm() {
    this.additionalHltCareForm = new UntypedFormGroup({
      industryCode: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.industryCode, '^[a-zA-Z0-9]+$', true, '1')]),
      //codeFromDate: new UntypedFormControl('', [this.getValidators('', '^(1[0-2]|0?[1-9])/(3[01]|[12][0-9]|0?[1-9])/(?:[0-9]{2})?[0-9]{2}$', this.displayCodeFrom(), '')]),
      codeFromDate: new UntypedFormControl('', [this.getValidators('', '', this.displayCodeFrom(), ''), dateValidator()]),
      codeThroughDate: new UntypedFormControl('', [this.getValidators('', '^(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])-(?:[0-9]{2})?[0-9]{2}$', this.displayCodeThroughDate(), '')]),
      dollarField: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.dollar, '^[0-9]+$', this.displayAmountField(), '')]),
      centField: new UntypedFormControl('', [this.getValidators(this.maxLengthInfo.cent, '^[0-9]+$', false, '')]),
    });
    this.additionalHltCareForm.controls.industryCode.setValue(this.inputValue.industryCode);
    if (this.displayCodeFrom()) {
      this.additionalHltCareForm.controls.codeFromDate.setValue((this.datePipe.transform(this.getAddHealthDate(this.inputValue), this.dateFormat)));
    }
    if (this.displayCodeThroughDate()) {
      this.additionalHltCareForm.controls.codeThroughDate.setValue(this.getAddHealthDate(this.inputValue, true));
    }
    if (this.displayAmountField()) {
      const splitDollar = this.inputValue.monetaryAmount.toString().split(".");
      const dollar = splitDollar[0];
      const cent = splitDollar.length > 1 ? splitDollar[1] : '';
      this.additionalHltCareForm.controls.dollarField.setValue(dollar);
      this.additionalHltCareForm.controls.centField.setValue(cent);
    }
  }
  getValidators(maxLength, pattern, required?, minLength?) {
    const validators: ValidatorFn[] = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength));
    }
    if (pattern) {
      validators.push(Validators.pattern(pattern));
    }
    return Validators.compose(validators);
  }
  validate() {
    const event = {
      type: 'validation',
      isInvalid: this.additionalHltCareForm.status === "INVALID",
      field: this.inputLabel
    }
    this.validateFields(event);
    this.collapseAdditionalHltcreFields.emit(event);
  }

  
  changeClick() {
    const event = {
      type: 'change',
      value: this.inputValue,
      field: this.inputLabel,
      isInvalid: false
    };
    event.isInvalid = this.isDateRangeInvalid() || this.additionalHltCareForm.status === "INVALID";
    if (!event.isInvalid) {
      this.inputValue.industryCode = this.additionalHltCareForm.get('industryCode').value;
      if (this.displayCodeFrom()) {
        const dateValue = this.additionalHltCareForm.get('codeFromDate').value;
        const formattedDate = this.datePipe.transform(dateValue, 'MM-dd-yyyy');
        this.inputValue.dTP = this.encodeDateFormat(formattedDate);
      }
      if (this.displayCodeThroughDate()) {
        const dateValue = this.additionalHltCareForm.get('codeThroughDate').value;
        const formattedDate = this.datePipe.transform(dateValue, 'MM-dd-yyyy');
        this.inputValue.dTP = this.inputValue.dTP + '-' + this.encodeDateFormat(formattedDate);
      }
      if (this.displayAmountField()) {
        const cent = this.additionalHltCareForm.get('centField').value;
        this.inputValue.monetaryAmount = this.additionalHltCareForm.get('dollarField').value + (cent === "" ? "" : "." + cent);
      }
      event.value = this.inputValue;
    } else {
      this.validateFields(event);
    }
    this.collapseAdditionalHltcreFields.emit(event);
  }
  validateFields(event){
    event['industryCode'] = this.additionalHltCareForm.controls.industryCode.status === 'INVALID';
      if (this.displayCodeFrom()) {
        event['codeFromDate'] = this.additionalHltCareForm.controls.codeFromDate.status === 'INVALID';
      }
      if (this.displayCodeThroughDate()) {
        event['codeThroughDate'] = this.isDateRangeInvalid() || this.additionalHltCareForm.controls.codeThroughDate.status === 'INVALID';
      }
      if (this.displayAmountField()) {
        event['amount'] = this.additionalHltCareForm.controls.dollarField.status === 'INVALID' || this.additionalHltCareForm.controls.centField.status === 'INVALID';
      }
  }
  displayCodeFrom() {
    return this.codeFromQulList.indexOf(this.inputValue.codeListQualifierCode) === -1;
  }

  displayCodeThroughDate() {
    return this.inputValue.codeListQualifierCode === this.CodeThroughQualifier;
  }

  displayAmountField() {
    return this.inputValue.codeListQualifierCode === this.amountQualifier;
  }

  mousedownEvent(event) {
    event.preventDefault();
  }

  getFormControlValidity(frmControlNm) {
    let isInvalid: boolean = false;
    isInvalid = this.additionalHltCareForm.controls[frmControlNm].status === "INVALID";
    if (isInvalid && !this.viewLoaded) {
      this.validate();
    }
    return isInvalid;
  }

  isDateRangeInvalid() {
    if(this.displayCodeThroughDate()){
      const fromDate = new Date(this.additionalHltCareForm.get('codeFromDate').value).getTime();
      const throughDate = new Date(this.additionalHltCareForm.get('codeThroughDate').value).getTime();
      const result = isNaN(throughDate) && isNaN(fromDate) || throughDate < fromDate;
      return result
    }
  }
}
